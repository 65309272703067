import React, {useState} from "react";
import Stars from "./stars";
const ChamberReviews = () =>{

    const [isMore, setMore] = useState(false)
    const handleClick = () => {
        return setMore(!isMore)
    }
        
    const reviewsChamb = [
        {
            name:"Denise R.",
            date:"03/01/2023",
            text:"We had a lot to do because we had death in the family and there was a lot of extra things out of place and unorganized, just in a rut, it was so helpful. ",
            stars:4
        },
        {
            name:"Ostavia H.",
            date:"01/24/2023",
            text:"Very communicative very lovely ladies definitely hiring again",
            stars:5
        },
        {
            name:"Allyson F.",
            date:"01/19/2023",
            text:"She showed up on time for both the estimate and the cleaning. We are very pleased with the cleaning. She went over and above what I have typically seen in previous cleaners. I highly recommend Milca’s Cleaning Service",
            stars:5
        },
        {
            name:"Debby M.",
            date:"01/20/2022",
            text:"Excellent job with great attention to detail. Deep cleaning was needed and the results were worth the price. I highly recommend these kind, hardworking professionals. ",
            stars:5
        },
        {
            name:"Mary G.",
            date:"12/13/2021",
            text:"Very thorough and efficient. Even dusted my ceiling fans! Milca asked me to walk through whole house to be sure I was satisfied before she left ",
            stars:5
        },
        {
            name:"John F.",
            date:"10/11/2021",
            text:"Milca is awesome. She is hard working and very thorough.",
            stars:5
        },
        {
            name:"Barb B.",
            date:"07/07/2021",
            text:"I am super happy with Milca and her team. We have them clean every two weeks and she does an excellent job and is a really nice, honest and hard working person as well. You can tell that she loves her job! Quality of the work is excellent and you will be happy using Milca!! ",
            stars:5
        },
        {
            name:"Liliana O.",
            date:"06/28/2021",
            text:"The cleaning was great everything was dusted,polished,vacuumed and mopped. They also did the windows but at extra cost but it’s was great! I highly recommend with out a doubt",
            stars:5
        },
        {
            name:"Barb B.",
            date:"02/10/2021",
            text:"Wow! Milca and her team did a fabulous job on our home. She really goes the extra mile to make everything sparkle and shine. I have asked her to do my home every two weeks going forward. I highly recommend her if you are looking for someone who really takes pride in their work!!",
            stars:5
        },
        {
            name:"Kelly M.",
            date:"12/14/2020",
            text:"I was very happy with the cleanliness of my home and will definitely request return visits I’m a busy person who needs help and was very satisfied with her service today.",
            stars:5
        },
        {
            name:"Nadine K.",
            date:"09/10/2020",
            text:"It is going great! Milca and her associate are cleaning my house and my mother's house monthly. They do a fantastic job.",
            stars:5
        },
        {
            name:"Jean L.",
            date:"09/04/2020",
            text:"Milca did a great job of cleaning my house! She cleaned things that hadn't been cleaned for a long time. She was very thorough and showed great attention to detail. When she arrived she went right to work and didn't stop. I am very happy with the service she provided ",
            stars:5
        },
        {
            name:"Elizabeth A.",
            date:"08/24/2020",
            text:"They were very punctual and did a good job of cleaning the whole house. We have her coming on a monthly basis. Very pleased ",
            stars:5
        },
        {
            name:"Diane S.",
            date:"02/11/2020",
            text:"Overall everything was fine. They did a good job.",
            stars:5
        },
        {
            name:"Carmen P.",
            date:"12/02/2019",
            text:"Milca's did an outstanding job. They are professionals and clean everything very well. The house smelled great afterwards. I would hire them again. Very happy with their services.",
            stars:5
        },
        {
            name:"Martin D.",
            date:"09/24/2019",
            text:"Went right to work and very detailed",
            stars:5
        },
        {
            name:"Brooke D.",
            date:"08/18/2019",
            text:"Milca’s team cleaned top to bottom, even the toaster oven was sparkling clean! She included all windows, which looked the best we’ve ever seen them when she was done. Her team was professional and every detail was covered. Would highly recommend Milca for a deep cleaning or ongoing cleaning.",
            stars:5
        },
        {
            name:"JOANN E.",
            date:"07/30/2019",
            text:"I am so very impressed with this company. My house hasn't looked so good or been so clean or smelled so fresh in a long time. The ladies didn't miss anything. the floors were swept and mopped, the carpets were vacuumed, the bathrooms and kitchen are spotless, and all the mirrors and countertops were cleaned and shined. I am definitely hiring them to come on a regular basis. ",
            stars:5
        },
        {
            name:"Yvette H.",
            date:"04/29/2019",
            text:"They literally cleaned to to bottom. Starting with fans, bathrooms, etc. when they got to the floors they moved all the furniture so they could get under and behind it. And let me just say my house smelled awesome!!!! My husbands response was “WOW” when he walked in from coming home from work. Overall I am very very pleased and scheduled bi-weekly cleaning service with her at a very reasonable fee. ",
            stars:5
        },
        {
            name:"Richard M.",
            date:"04/01/2019",
            text:"I had just moved in and called around to cleaning services from Angie's List. Milca's cleaning service answered and was able to come the same day. After moving in I noticed how dirty the house was. Milca stayed until the entire house was clean. She spent 8 hours cleaning the house from top to bottom. I am widowed and require a cleaning service once a month. Milca is my first and only choice.",
            stars:5
        },
        {
            name:"Diane W.",
            date:"02/28/2019",
            text:"Wonderful! My house has never been this clean!",
            stars:5
        },
        {
            name:"Tresa L.",
            date:"02/24/2019",
            text:"Great. Have others cleaning my home but Milca has surpassed all my expectations . I highly recommend Milca.",
            stars:5
        },
        {
            name:"Patricia L.",
            date:"12/20/2018",
            text:"House was so CLEAN and FRESH! Milca did inside windows, wiped down all interior doors, baseboards, washed floors, washed sheets and made all beds. She even cleaned exterior of stainless steel refrigerator ( tends to get smudgy easily ) and it looked amazing! She did a thorough job, appears to have gone above and beyond. Thank you Milca!",
            stars:5
        },
        {
            name:"donna W.",
            date:"11/05/2018",
            text:"Milca was great her and Rosario(hope I spelled right) came in and went to work since my mother is disabled they had a task but they completed beautifully. They cleaned windows ,washed curtains, dusted, swept, cleaned bathrooms, moped floors and anything else my mom needed to be done. Would recommend this business to everyone.",
            stars:5
        },
        {
            name:"David B.",
            date:"12/22/2022",
            text:"Great and thorough work.",
            stars:5
        },
        {
            name:"Ted B.",
            date:"09/01/2020",
            text:"Went well.",
            stars:5
        },
        {
            name:"Lori V.",
            date:"10/18/2018",
            text:"Milca has been cleaning for me for approximately three years. I highly recommend her she is honest and trustworthy! Her and her assistant do a thorough job! Ceiling fans, under sofa, beds, windows, you name it she cleans it!",
            stars:5
        },
        {
            name:"David R.",
            date:"11/18/2017",
            text:"They did a fantastic job and will use them again.",
            stars:5
        },
        {
            name:"Adrian M.",
            date:"11/10/2017",
            text:"It Went Very Well I Liked the Job Dome at My Home The Best cleaning i ever seen!",
            stars:5
        },
        {
            name:"sandra R.",
            date:"11/01/2017",
            text:"she did a great work at cleaning my house.",
            stars:5
        },
        {
            name:"Eligha P.",
            date:"10/31/2017",
            text:"Very well. Milca, was prompt and very professional. Had all of the tools, supplies, and staff to perform the duties prompt and efficiently. When services were completed home was in move in ready condition.",
            stars:5
        },
        {
            name:"Jeanette H.",
            date:"06/29/2017",
            text:"She's very very good and careful not to break things.",
            stars:5
        },
        {
            name:"Theresa D.",
            date:"06/29/2017",
            text:"She does more than dust. She has even done laundry and cleaned out the refrigerator before. Her work covers both the seen and unseen. She does such a great job, and she is very dependable. I trust her as well, even when I'm not home while she's working.",
            stars:5
        },

    ]

    return(
        <section className="w-full py-20">
            <h2 className="text-center">Our Reviews</h2>
                <a href="https://www.angi.com/companylist/us/fl/vero-beach/milca%27s-cleaning-service-reviews-9311008.htm"
                target="_blank"
                rel="noreferrer"
                className="flex w-[300px] mx-auto justify-center">
                    <img
                    alt="logochamber"
                    src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fangi.JPG?alt=media&token=28acaf6c-3d0b-49f0-a73c-02c62db8b9a7"
                   />
                </a> 
                

                {
                    isMore?
                    reviewsChamb.map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium">{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>

                        </section>
                        )
                    })
                    :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium" >{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button>
        </section> 
    );

}

export default ChamberReviews